<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <finance-metrics class="mb-5"/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import FinanceMetrics from "@/components/Book/FinanceMetrics";

export default {
  name: 'FinanceOverview',
  components: {
    FinanceMetrics,
    TitleBar
  },
  data: () => {
    return {
    }
  },
  computed: {
    titleStack() {
      return ['SgaraFinance ', 'Overview']
    }
  },
}
</script>
