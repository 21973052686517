<template>
  <div>
    <!--   Finance   -->
    <card-component
      title="Finance"
      icon="finance"
      hide-content
    />

    <tiles>
      <card-widget
        class="tile is-child"
        type="is-primary"
        icon="chart-bubble"
        :number="metrics.mentored_farms.value"
        label="Count of Farm used Mentoring"
      >
        <b-loading :is-full-page="false" v-model="metrics.mentored_farms.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-primary"
        icon="all-inclusive"
        :number="metrics.mentored_ponds.value"
        label="Count of Ponds used Mentoring"
      >
        <b-loading :is-full-page="false" v-model="metrics.mentored_ponds.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-primary"
        icon="warehouse"
        :number="metrics.products_sold.value"
        label="Sum of Product (Feed/Probiotics) Sold"
      >
        <b-loading :is-full-page="false" v-model="metrics.products_sold.loading"></b-loading>
      </card-widget>
    </tiles>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import Tiles from "@/components/Tiles";
import CardWidget from "@/components/CardWidget";
import {mapActions, mapState} from "vuex";

export default {
  name: "FinanceMetrics",
  components: {CardWidget, Tiles, CardComponent},
  created() {
    this.countMentoredFarms()
    this.countMentoredPonds()
    this.countProductsSold()
  },
  computed: {
    ...mapState('finance', [
      'metrics',
    ]),
  },
  methods: {
    ...mapActions('finance', [
      'countMentoredFarms',
      'countMentoredPonds',
      'countProductsSold',
    ])
  },
}
</script>

<style scoped>

</style>
